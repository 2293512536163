import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 2 }
const _hoisted_5 = { class: "list-unstyled" }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { key: 2 }

import { useRegionStore } from "../store/region";
import { computed } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'RegionInfoModal',
  props: {
  region: {
    type: Object,
    required: true
  },
  regionLoading: {
    type: Boolean,
    required: true
  }
},
  setup(__props) {

const regionStore = useRegionStore();

const props = __props;

return (_ctx: any,_cache: any) => {
  return (props.region)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (props.region.owner_id)
          ? (_openBlock(), _createElementBlock("p", _hoisted_2, "Tady kraluje: " + _toDisplayString(props.region.owner_name) + " #" + _toDisplayString(props.region.owner_id), 1))
          : (_openBlock(), _createElementBlock("p", _hoisted_3, "Volné území")),
        (props.region.resource_amounts)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _cache[0] || (_cache[0] = _createElementVNode("h6", null, "Zdroje na území", -1)),
              _createElementVNode("ul", _hoisted_5, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.region.resource_amounts, (production) => {
                  return (_openBlock(), _createElementBlock("li", null, _toDisplayString(production.type.name) + ": " + _toDisplayString(production.amount), 1))
                }), 256))
              ])
            ]))
          : _createCommentVNode("", true)
      ]))
    : (props.regionLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, _cache[1] || (_cache[1] = [
          _createElementVNode("p", null, "Načítání regionu...", -1)
        ])))
      : (_openBlock(), _createElementBlock("div", _hoisted_7, _cache[2] || (_cache[2] = [
          _createElementVNode("p", null, "Chyba při načítání regionu.", -1)
        ])))
}
}

})