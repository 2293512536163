import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, withModifiers as _withModifiers, vModelText as _vModelText, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "list-unstyled" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 2 }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { key: 2 }
const _hoisted_10 = {
  key: 3,
  class: "pt-3"
}
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { key: 1 }
const _hoisted_13 = {
  key: 4,
  class: "pt-3"
}
const _hoisted_14 = { key: 0 }
const _hoisted_15 = {
  id: "hire_hero",
  class: "collapse fade"
}
const _hoisted_16 = { key: 1 }
const _hoisted_17 = {
  class: "btn btn-link p-0",
  "data-bs-toggle": "collapse",
  "data-bs-target": "#upgrade_building"
}
const _hoisted_18 = {
  id: "upgrade_building",
  class: "collapse fade"
}
const _hoisted_19 = { class: "list-unstyled" }
const _hoisted_20 = {
  key: 0,
  class: "text-danger"
}
const _hoisted_21 = { key: 2 }
const _hoisted_22 = {
  id: "demolish_building",
  class: "collapse fade"
}
const _hoisted_23 = { class: "list-unstyled" }
const _hoisted_24 = { key: 3 }
const _hoisted_25 = {
  id: "steal_building",
  class: "collapse fade"
}
const _hoisted_26 = {
  key: 4,
  class: "mt-1 alert alert-danger"
}
const _hoisted_27 = { key: 0 }
const _hoisted_28 = { key: 1 }
const _hoisted_29 = {
  key: 5,
  class: "mt-1 alert alert-danger"
}
const _hoisted_30 = { key: 0 }
const _hoisted_31 = { key: 1 }
const _hoisted_32 = { key: 1 }
const _hoisted_33 = { key: 2 }
const _hoisted_34 = { key: 3 }
const _hoisted_35 = { key: 0 }
const _hoisted_36 = {
  key: 0,
  class: "list-unstyled"
}
const _hoisted_37 = { key: 1 }

import { useBuildingStore } from "../store/building";
import { usePlayerStore } from "../store/player";
import { computed, ref } from "vue";
import Timer from "./Timer.vue";
import Loading from "./Loading.vue";
import { BuildingState, Building, Hero } from "../type";
import { useHeroStore } from "../store/hero";
import { useInventoryStore } from '../store/inventory';
import { useMapStore } from '../store/map';


export default /*@__PURE__*/_defineComponent({
  __name: 'BuildingInfoModal',
  props: {
  building: {
    type: Object,
    required: true
  },
  buildingLoading: {
    type: Boolean,
    required: true
  },
  isInRange: {
    type: Boolean,
    required: true
  }
},
  setup(__props) {

const buildingStore = useBuildingStore();
const playerStore = usePlayerStore();
const heroStore = useHeroStore();
const inventoryStore = useInventoryStore();
const mapStore = useMapStore();

const props = __props;

const hireHeroData = ref<{name: string}>({name: ''});

const isBuildingOwner = computed(() => {
  return playerStore.player && props.building && props.building.owner.id === playerStore.player.id;
});

const canUpgradeBuilding = computed(() => {
  return buildingStore.canUpgradeCurrentBuilding;
});

const canPickUpResources = computed(() => {
  return props.building?.stock?.length > 0 && props.isInRange;
});

function pickUpResources() {
  if (!props.building) {
    return;
  }

  buildingStore.pickUpResources(props.building as Building)
    .then((updatedBuilding: Building| null) => {
      if (updatedBuilding) {
        buildingStore.currentBuilding = updatedBuilding;
      }
      playerStore.fetchPlayer();
    });
}

function upgradeBuilding() {
  if (!props.isInRange) {
    return;
  }

  const currentBuilding = buildingStore.currentBuilding;
  if (!currentBuilding || !canUpgradeBuilding.value) {
    return;
  }

  if (currentBuilding.state !== BuildingState.BUILT) {
    return;
  }

  if (currentBuilding.level >= currentBuilding.type.max_level) {
    return;
  }

  buildingStore.upgradeBuilding(currentBuilding)
    .then((upgradedBuilding) => {
      if (upgradedBuilding) {
        buildingStore.currentBuilding = upgradedBuilding as Building;
      }
      playerStore.fetchPlayer();
    });
}

function demolishBuilding() {
  if (!props.isInRange) {
    return;
  }

  const currentBuilding = buildingStore.currentBuilding;
  if (!currentBuilding || currentBuilding.state !== BuildingState.BUILT) {
    return;
  }

  buildingStore.demolishBuilding(currentBuilding)
    .then((demolishedBuilding: Building) => {
      buildingStore.currentBuilding = demolishedBuilding;
    });
}

function hireHero() {
  if (!props.building || props.building.type.slug !== 'base' || !props.isInRange) {
    return;
  }

  heroStore.hire(hireHeroData.value)
    .then((hero: Hero) => {
      if (playerStore.player) {
        playerStore.player.hero = hero;
      }
    });
}

function stealBuilding() {
  if (!props.isInRange) {
    return;
  }

  const currentBuilding = buildingStore.currentBuilding;
  if (!currentBuilding || currentBuilding.state !== BuildingState.BUILT) {
    return;
  }

  buildingStore.stealBuilding(currentBuilding)
    .then((stolenBuilding) => {
      playerStore.fetchPlayer();
    });
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (props.building)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("ul", _hoisted_2, [
            _createElementVNode("li", null, " typ: " + _toDisplayString(props.building.type.name), 1),
            (props.building.owner.id !== _unref(playerStore).player?.id)
              ? (_openBlock(), _createElementBlock("li", _hoisted_3, " vlastník: " + _toDisplayString(props.building.owner.nickname), 1))
              : _createCommentVNode("", true),
            (props.building.state)
              ? (_openBlock(), _createElementBlock("li", _hoisted_4, [
                  _createTextVNode(" stav: " + _toDisplayString(props.building.state) + " ", 1),
                  (props.building.state_valid_to)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
                        _cache[7] || (_cache[7] = _createTextVNode(" (")),
                        _createVNode(Timer, {
                          "end-date": new Date(props.building.state_valid_to)
                        }, null, 8, ["end-date"]),
                        _cache[8] || (_cache[8] = _createTextVNode(") "))
                      ]))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true),
            (props.building.state === _unref(BuildingState).BUILT && isBuildingOwner.value && canPickUpResources.value)
              ? (_openBlock(), _createElementBlock("li", _hoisted_6, [
                  _cache[10] || (_cache[10] = _createTextVNode(" sklad: ")),
                  (props.building?.stock?.length)
                    ? (_openBlock(), _createElementBlock("ul", _hoisted_7, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.building.stock, (stock) => {
                          return (_openBlock(), _createElementBlock("li", null, _toDisplayString(stock.type.name) + ": " + _toDisplayString(stock.amount), 1))
                        }), 256))
                      ]))
                    : (_openBlock(), _createElementBlock("span", _hoisted_8, " prázdný")),
                  (canPickUpResources.value)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                        _createElementVNode("button", {
                          class: "btn btn-sm btn-primary",
                          onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (pickUpResources()), ["prevent"]))
                        }, [
                          _cache[9] || (_cache[9] = _createTextVNode(" Vyzvednout suroviny ")),
                          (_unref(buildingStore).pickUpLoading)
                            ? (_openBlock(), _createBlock(Loading, { key: 0 }))
                            : _createCommentVNode("", true)
                        ])
                      ]))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true),
            (props.building.state === _unref(BuildingState).DEMOLISHED)
              ? (_openBlock(), _createElementBlock("li", _hoisted_10, [
                  _cache[12] || (_cache[12] = _createTextVNode(" suroviny v troskách: ")),
                  (props.building?.stock?.length)
                    ? (_openBlock(), _createElementBlock("ul", _hoisted_11, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.building.stock, (stock) => {
                          return (_openBlock(), _createElementBlock("li", null, _toDisplayString(stock.type.name) + ": " + _toDisplayString(stock.amount), 1))
                        }), 256))
                      ]))
                    : _createCommentVNode("", true),
                  (canPickUpResources.value)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                        _createElementVNode("button", {
                          class: "btn btn-sm btn-primary",
                          onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (pickUpResources()), ["prevent"]))
                        }, [
                          _cache[11] || (_cache[11] = _createTextVNode(" Vyzvednout trosky ")),
                          (_unref(buildingStore).pickUpLoading)
                            ? (_openBlock(), _createBlock(Loading, { key: 0 }))
                            : _createCommentVNode("", true)
                        ])
                      ]))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true),
            (props.building.state === _unref(BuildingState).BUILT)
              ? (_openBlock(), _createElementBlock("li", _hoisted_13, [
                  (!_unref(playerStore).player?.hero && props.building.type.slug === 'base' && props.isInRange)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                        _cache[15] || (_cache[15] = _createElementVNode("button", {
                          class: "btn btn-sm btn-secondary",
                          "data-bs-toggle": "collapse",
                          "data-bs-target": "#hire_hero"
                        }, " Najmout hrdinu ", -1)),
                        _createElementVNode("div", _hoisted_15, [
                          _cache[14] || (_cache[14] = _createElementVNode("label", {
                            for: "hero_name",
                            class: "form-label"
                          }, "Jméno hrdiny", -1)),
                          _withDirectives(_createElementVNode("input", {
                            type: "text",
                            class: "form-control",
                            id: "hero_name",
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((hireHeroData.value.name) = $event))
                          }, null, 512), [
                            [_vModelText, hireHeroData.value.name]
                          ]),
                          _createElementVNode("button", {
                            class: "btn btn-sm btn-success mt-2",
                            onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (hireHero()), ["prevent"]))
                          }, [
                            _cache[13] || (_cache[13] = _createTextVNode(" Najmout ")),
                            (_unref(heroStore).hireLoading)
                              ? (_openBlock(), _createBlock(Loading, { key: 0 }))
                              : _createCommentVNode("", true)
                          ])
                        ])
                      ]))
                    : _createCommentVNode("", true),
                  (isBuildingOwner.value && props.building.level < props.building.type.max_level)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                        _createElementVNode("button", _hoisted_17, " Upgrade na " + _toDisplayString(props.building.level + 1) + " úroveň ", 1),
                        _createElementVNode("div", _hoisted_18, [
                          _cache[16] || (_cache[16] = _createElementVNode("strong", null, "Náklady:", -1)),
                          _createElementVNode("ul", _hoisted_19, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.building.upgrade_cost, (cost) => {
                              return (_openBlock(), _createElementBlock("li", null, [
                                _createTextVNode(_toDisplayString(cost.type.name) + ": " + _toDisplayString(cost.amount) + " ", 1),
                                (_unref(playerStore).findResource(cost.type.slug).amount < cost.amount)
                                  ? (_openBlock(), _createElementBlock("strong", _hoisted_20, " (chybí " + _toDisplayString(cost.amount - _unref(playerStore).findResource(cost.type.slug).amount) + " !!) ", 1))
                                  : _createCommentVNode("", true)
                              ]))
                            }), 256))
                          ]),
                          (canUpgradeBuilding.value && props.isInRange)
                            ? (_openBlock(), _createElementBlock("button", {
                                key: 0,
                                class: "btn btn-sm btn-success",
                                onClick: _cache[4] || (_cache[4] = _withModifiers(($event: any) => (upgradeBuilding()), ["prevent"]))
                              }, [
                                _createTextVNode(" Upgrade na úroveň " + _toDisplayString(props.building.level + 1) + " ", 1),
                                (_unref(buildingStore).upgradeLoading)
                                  ? (_openBlock(), _createBlock(Loading, { key: 0 }))
                                  : _createCommentVNode("", true)
                              ]))
                            : _createCommentVNode("", true)
                        ])
                      ]))
                    : _createCommentVNode("", true),
                  (isBuildingOwner.value && props.isInRange)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                        _cache[19] || (_cache[19] = _createElementVNode("button", {
                          class: "btn btn-link p-0",
                          "data-bs-toggle": "collapse",
                          "data-bs-target": "#demolish_building"
                        }, " Zbourat ", -1)),
                        _createElementVNode("div", _hoisted_22, [
                          _cache[18] || (_cache[18] = _createElementVNode("strong", null, "Zisk surovin:", -1)),
                          _createElementVNode("ul", _hoisted_23, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.building.type.demolish_gain, (cost) => {
                              return (_openBlock(), _createElementBlock("li", null, _toDisplayString(cost.type.name) + ": " + _toDisplayString(cost.amount), 1))
                            }), 256))
                          ]),
                          _createElementVNode("button", {
                            class: "btn btn-sm btn-danger",
                            onClick: _cache[5] || (_cache[5] = _withModifiers(($event: any) => (demolishBuilding()), ["prevent"]))
                          }, [
                            _cache[17] || (_cache[17] = _createTextVNode(" Zbourat ")),
                            (_unref(buildingStore).demolishLoading)
                              ? (_openBlock(), _createBlock(Loading, { key: 0 }))
                              : _createCommentVNode("", true)
                          ])
                        ])
                      ]))
                    : _createCommentVNode("", true),
                  (isBuildingOwner.value === false && props.isInRange && _unref(playerStore).player?.hero && _unref(buildingStore).stealResult?.building.uuid !== props.building.uuid)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                        _cache[21] || (_cache[21] = _createElementVNode("button", {
                          class: "btn btn-link p-0",
                          "data-bs-toggle": "collapse",
                          "data-bs-target": "#steal_building"
                        }, " Vykrást ", -1)),
                        _createElementVNode("div", _hoisted_25, [
                          _createElementVNode("button", {
                            class: "btn btn-sm btn-danger",
                            onClick: _cache[6] || (_cache[6] = _withModifiers(($event: any) => (stealBuilding()), ["prevent"]))
                          }, [
                            _cache[20] || (_cache[20] = _createTextVNode(" Vykrást ")),
                            (_unref(buildingStore).stealLoading)
                              ? (_openBlock(), _createBlock(Loading, { key: 0 }))
                              : _createCommentVNode("", true)
                          ])
                        ])
                      ]))
                    : _createCommentVNode("", true),
                  (_unref(buildingStore).upgradeError)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
                        (_unref(buildingStore).upgradeError.error === 'too-far')
                          ? (_openBlock(), _createElementBlock("span", _hoisted_27, "Budova je příliš daleko"))
                          : (_openBlock(), _createElementBlock("span", _hoisted_28, _toDisplayString(_unref(buildingStore).upgradeError.message), 1))
                      ]))
                    : _createCommentVNode("", true),
                  (_unref(buildingStore).pickUpError)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_29, [
                        (_unref(buildingStore).pickUpError.error === 'too-far')
                          ? (_openBlock(), _createElementBlock("span", _hoisted_30, "Budova je příliš daleko"))
                          : (_openBlock(), _createElementBlock("span", _hoisted_31, _toDisplayString(_unref(buildingStore).pickUpError.message), 1))
                      ]))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true)
          ])
        ]))
      : (props.buildingLoading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_32, [
            _createVNode(Loading)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_33, _cache[22] || (_cache[22] = [
            _createElementVNode("p", null, "Chyba při načítání budovy.", -1)
          ]))),
    (props.building && _unref(buildingStore).stealResult)
      ? (_openBlock(), _createElementBlock("div", _hoisted_34, [
          _createElementVNode("div", null, [
            _cache[24] || (_cache[24] = _createElementVNode("h6", null, "Výsledek boje", -1)),
            _createElementVNode("p", null, [
              _createTextVNode(" Vítěz: " + _toDisplayString(_unref(buildingStore).stealResult.combat_result.winner) + " ", 1),
              _cache[23] || (_cache[23] = _createElementVNode("br", null, null, -1)),
              _createTextVNode(" Poražený: " + _toDisplayString(_unref(buildingStore).stealResult.combat_result.loser), 1)
            ])
          ]),
          (_unref(buildingStore).stealResult.combat_result.is_win)
            ? (_openBlock(), _createElementBlock("div", _hoisted_35, [
                _cache[25] || (_cache[25] = _createElementVNode("h6", null, "Lup", -1)),
                (_unref(buildingStore).stealResult.loot.length > 0)
                  ? (_openBlock(), _createElementBlock("ul", _hoisted_36, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(buildingStore).stealResult.loot, (loot) => {
                        return (_openBlock(), _createElementBlock("li", null, _toDisplayString(loot.type.name) + ": " + _toDisplayString(loot.amount), 1))
                      }), 256))
                    ]))
                  : (_openBlock(), _createElementBlock("p", _hoisted_37, "Nic tu není :("))
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})