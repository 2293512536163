import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

import { useInventoryStore } from "../store/inventory";
import { computed } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'ItemInfoModal',
  props: {
  item: {
    type: Object,
    required: true
  },
  isInRange: {
    type: Boolean,
    required: true
  },
  canPickUpItem: {
    type: Boolean,
    required: true
  }
},
  setup(__props) {

const inventoryStore = useInventoryStore();

const props = __props;

function pickUpItem() {
  if (props.item?.properties?.uuid) {
    inventoryStore.pickUp(props.item.properties.uuid)
      .then(() => {
        // emit('item-picked-up'); // TODO: emit event to parent component
      });
  }
}

return (_ctx: any,_cache: any) => {
  const _component_loading = _resolveComponent("loading")!

  return (props.item)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("p", null, _toDisplayString(props.item.properties?.description || ''), 1),
        (props.isInRange && props.canPickUpItem)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              class: "btn btn-sm btn-primary",
              onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (pickUpItem()), ["prevent"]))
            }, [
              _cache[1] || (_cache[1] = _createTextVNode(" Vyzvednout ")),
              (_unref(inventoryStore).pickUpLoading)
                ? (_openBlock(), _createBlock(_component_loading, { key: 0 }))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, _cache[2] || (_cache[2] = [
        _createElementVNode("p", null, "Chyba při načítání předmětu.", -1)
      ])))
}
}

})