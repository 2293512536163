import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { useHelpStore } from '../store/help';
import Modal from './Modal.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'HelpModal',
  setup(__props) {

const helpStore = useHelpStore();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(Modal, {
      id: "help-modal",
      show: _unref(helpStore).isOpen
    }, {
      header: _withCtx(() => _cache[0] || (_cache[0] = [
        _createElementVNode("h5", {
          class: "modal-title",
          id: "help-modal-label"
        }, "Nápověda", -1),
        _createElementVNode("button", {
          type: "button",
          class: "btn-close",
          "data-bs-dismiss": "modal",
          "aria-label": "Zavřít"
        }, null, -1)
      ])),
      body: _withCtx(() => _cache[1] || (_cache[1] = [
        _createElementVNode("div", {
          class: "accordion",
          id: "helpAccordion"
        }, [
          _createElementVNode("div", { class: "accordion-item" }, [
            _createElementVNode("h2", {
              class: "accordion-header",
              id: "headingOne"
            }, [
              _createElementVNode("button", {
                class: "accordion-button",
                type: "button",
                "data-bs-toggle": "collapse",
                "data-bs-target": "#collapseOne",
                "aria-expanded": "true",
                "aria-controls": "collapseOne"
              }, " Základní herní koncepty ")
            ]),
            _createElementVNode("div", {
              id: "collapseOne",
              class: "accordion-collapse collapse show",
              "aria-labelledby": "headingOne",
              "data-bs-parent": "#helpAccordion"
            }, [
              _createElementVNode("div", { class: "accordion-body" }, " Cílem hry je vybudovat prosperující civilizaci. Začínáte s malým osídlením a postupně rozšiřujete své území, stavíte budovy, získáváte suroviny a rozvíjíte své hrdiny. Důležité je strategické plánování a efektivní správa zdrojů. ")
            ])
          ]),
          _createElementVNode("div", { class: "accordion-item" }, [
            _createElementVNode("h2", {
              class: "accordion-header",
              id: "headingTwo"
            }, [
              _createElementVNode("button", {
                class: "accordion-button collapsed",
                type: "button",
                "data-bs-toggle": "collapse",
                "data-bs-target": "#collapseTwo",
                "aria-expanded": "false",
                "aria-controls": "collapseTwo"
              }, " Ovládání hry ")
            ]),
            _createElementVNode("div", {
              id: "collapseTwo",
              class: "accordion-collapse collapse",
              "aria-labelledby": "headingTwo",
              "data-bs-parent": "#helpAccordion"
            }, [
              _createElementVNode("div", { class: "accordion-body" }, " Hra se ovládá primárně myší. Kliknutím na volné políčko na mapě otevřete nabídku staveb. Kliknutím na budovy a další objekty v herním světě s nimi interagujete. Pro rychlejší navigaci a akce můžete používat klávesové zkratky (seznam zkratek bude doplněn). ")
            ])
          ]),
          _createElementVNode("div", { class: "accordion-item" }, [
            _createElementVNode("h2", {
              class: "accordion-header",
              id: "headingThree"
            }, [
              _createElementVNode("button", {
                class: "accordion-button collapsed",
                type: "button",
                "data-bs-toggle": "collapse",
                "data-bs-target": "#collapseThree",
                "aria-expanded": "false",
                "aria-controls": "collapseThree"
              }, " Budovy ")
            ]),
            _createElementVNode("div", {
              id: "collapseThree",
              class: "accordion-collapse collapse",
              "aria-labelledby": "headingThree",
              "data-bs-parent": "#helpAccordion"
            }, [
              _createElementVNode("div", { class: "accordion-body" }, " Budovy jsou základem vašeho osídlení. Stavěním budov získáte přístup k novým surovinám, jednotkám a vylepšením. Budovy se staví na místě, kde se hráč nachází v reálném světě, pomocí tlačítka \"Stavět\". Pro stavbu budovy klikněte na volné políčko a vyberte budovu z nabídky. Každá budova vyžaduje specifické suroviny a čas na výstavbu. Důležité budovy jsou hlavní budova (základna) a věže, které umožňují ovládnout region, ve kterém jsou postaveny. ")
            ])
          ]),
          _createElementVNode("div", { class: "accordion-item" }, [
            _createElementVNode("h2", {
              class: "accordion-header",
              id: "headingFour"
            }, [
              _createElementVNode("button", {
                class: "accordion-button collapsed",
                type: "button",
                "data-bs-toggle": "collapse",
                "data-bs-target": "#collapseFour",
                "aria-expanded": "false",
                "aria-controls": "collapseFour"
              }, " Suroviny ")
            ]),
            _createElementVNode("div", {
              id: "collapseFour",
              class: "accordion-collapse collapse",
              "aria-labelledby": "headingFour",
              "data-bs-parent": "#helpAccordion"
            }, [
              _createElementVNode("div", { class: "accordion-body" }, " Suroviny jsou nezbytné pro stavbu budov, verbování jednotek a další rozvoj. Suroviny získáte těžbou v surovinových budovách postavených v regionech zvýrazněných na mapě (např. dřevo z pily v lese, kámen z lomu v kamenolomu). Důležité je efektivně spravovat suroviny a zajistit jejich stálý přísun. ")
            ])
          ]),
          _createElementVNode("div", { class: "accordion-item" }, [
            _createElementVNode("h2", {
              class: "accordion-header",
              id: "headingFive"
            }, [
              _createElementVNode("button", {
                class: "accordion-button collapsed",
                type: "button",
                "data-bs-toggle": "collapse",
                "data-bs-target": "#collapseFive",
                "aria-expanded": "false",
                "aria-controls": "collapseFive"
              }, " Hrdinové ")
            ]),
            _createElementVNode("div", {
              id: "collapseFive",
              class: "accordion-collapse collapse",
              "aria-labelledby": "headingFive",
              "data-bs-parent": "#helpAccordion"
            }, [
              _createElementVNode("div", { class: "accordion-body" }, " Hrdinové jsou mocné jednotky s unikátními schopnostmi. Hrdinu získáte v hlavní budově (základně). Hrdinové vám pomáhají v boji proti nepřátelům, průzkumu nových území a plnění úkolů. Hrdina může sbírat předměty ve světě, které mu dávají bonusy. Pokud hrdina najde cizí budovu (sklad), může ji vyrabovat. Rozvíjejte schopnosti svých hrdinů a vybavte je kvalitní výbavou, aby se stali ještě silnějšími. ")
            ])
          ])
        ], -1)
      ])),
      _: 1
    }, 8, ["show"])
  ]))
}
}

})