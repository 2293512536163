import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  class: "modal-title fs-5",
  id: 'map_info-label'
}

import Modal from "./Modal.vue";
import RegionInfoModal from "./RegionInfoModal.vue";
import ResourceInfoModal from "./ResourceInfoModal.vue";
import BuildingInfoModal from "./BuildingInfoModal.vue";
import ItemInfoModal from "./ItemInfoModal.vue";

import {computed, ref, watch} from "vue";

import {useMapStore} from "../store/map";
import {useRegionStore} from "../store/region";
import {useResourceStore} from "../store/resource";
import {useBuildingStore} from "../store/building";
import {usePlayerStore} from "../store/player";
import {LatLng} from "leaflet";
import Loading from "@/assets/modules/game/components/Loading.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'MapInfoModal',
  setup(__props) {

const mapStore = useMapStore();
const regionStore = useRegionStore();
const resourceStore = useResourceStore();
const buildingStore = useBuildingStore();
const playerStore = usePlayerStore();

const show = ref<boolean>(false);
const dataType = ref<string|null>(null);
const data = ref<any|null>(null);

const title = computed(() => {
  if (!mapStore.selectedFeature) {
    return '';
  }

  let title = mapStore.selectedFeature.properties?.name || '';

  if (dataType.value === 'region') {
    title = 'Region: ' + title;
  }
  else if (dataType.value === 'resource') {
    if (!title && data.value?.properties) {
      title = data.value.properties.resource || '';
    }
    title = 'Zdroj: ' + title;
  }
  else if (dataType.value === 'building') {
    title = 'Budova: ' + title;
  }
  else if (dataType.value === 'item') {
    title = 'Předmět: ' + title;
  }

  return title;
});

const position = computed(() => {
    if (mapStore.selectedFeature && mapStore.selectedFeature.geometry.type === 'Point') {
        return new LatLng(
            mapStore.selectedFeature.geometry.coordinates[1],
            mapStore.selectedFeature.geometry.coordinates[0]
        );
    }
    return null;
});

const isInRange = computed(() => {
    if (!position.value || !playerStore.position || !playerStore.player) {
        return false;
    }

    return position.value.distanceTo(playerStore.position.latLng) <= playerStore.player.range;
});

const canPickUpItem = computed(() => {
  return !!playerStore.player?.hero;
});


const clear = () => {
  show.value = false;
  data.value = null;
  mapStore.selectedFeature = null;
}

watch(() => mapStore.selectedFeature, (newVal: any) => { // Type 'any' for simplicity
  if (newVal) {
    dataType.value = newVal.properties.type;
    data.value = newVal;

    if (dataType.value === 'region' && newVal.properties.id) {
      regionStore.fetchRegion(newVal.properties.id);
    }
    else if (dataType.value === 'resource' && newVal.properties.id) {
      resourceStore.fetchData(newVal.properties.id);
    }
    else if (dataType.value === 'building' && newVal.properties.uuid) {
      buildingStore.changeCurrentBuilding(newVal.properties.uuid);
    }

    show.value = true;
  }
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(Modal, {
    id: "map_info",
    show: show.value,
    onHidden: clear
  }, {
    header: _withCtx(() => [
      _createElementVNode("h1", _hoisted_1, [
        _createTextVNode(_toDisplayString(title.value) + " ", 1),
        _createElementVNode("small", {
          class: _normalizeClass(isInRange.value ? 'text-success' : 'text-black-50')
        }, "(" + _toDisplayString(isInRange.value ? 'v dosahu' : 'mimo dosah') + ")", 3)
      ]),
      _cache[0] || (_cache[0] = _createElementVNode("button", {
        "aria-label": "Close",
        class: "btn-close",
        "data-bs-dismiss": "modal",
        type: "button"
      }, null, -1))
    ]),
    body: _withCtx(() => [
      (_unref(regionStore).regionLoading || _unref(resourceStore).loading || _unref(buildingStore).currentBuildingLoading)
        ? (_openBlock(), _createBlock(Loading, { key: 0 }))
        : _createCommentVNode("", true),
      (dataType.value === 'region' && _unref(regionStore).region)
        ? (_openBlock(), _createBlock(RegionInfoModal, {
            key: 1,
            region: _unref(regionStore).region,
            regionLoading: _unref(regionStore).regionLoading
          }, null, 8, ["region", "regionLoading"]))
        : _createCommentVNode("", true),
      (dataType.value === 'resource' && _unref(resourceStore).resource)
        ? (_openBlock(), _createBlock(ResourceInfoModal, {
            key: 2,
            resource: _unref(resourceStore).resource,
            resourceLoading: _unref(resourceStore).loading
          }, null, 8, ["resource", "resourceLoading"]))
        : _createCommentVNode("", true),
      (dataType.value === 'building' && _unref(buildingStore).currentBuilding)
        ? (_openBlock(), _createBlock(BuildingInfoModal, {
            key: 3,
            building: _unref(buildingStore).currentBuilding,
            buildingLoading: _unref(buildingStore).currentBuildingLoading,
            isInRange: isInRange.value
          }, null, 8, ["building", "buildingLoading", "isInRange"]))
        : _createCommentVNode("", true),
      (dataType.value === 'item' && data.value)
        ? (_openBlock(), _createBlock(ItemInfoModal, {
            key: 4,
            item: data.value,
            isInRange: isInRange.value,
            canPickUpItem: canPickUpItem.value
          }, null, 8, ["item", "isInRange", "canPickUpItem"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["show"]))
}
}

})